import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <span className="blink">In development, Stay tuned...</span>
      </header>
    </div>
  );
}

export default App;